import axios from 'axios'
import api from '@/api'

import {
  VUEX_API_WOF_REQUEST_SUCCESS,
  VUEX_API_WOF_REQUEST_FAILED,

  VUEX_API_WOF_CUSTOMER_DETAILS_REQUEST,
  VUEX_API_WOF_CUSTOMER_AUTOCOMPLETE_REQUEST,

  VUE_API_WOF_SET_CUSTOMER_LIST,

  VUEX_API_WOF_SET_CANCEL_TOKEN
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {
  wofRequest: null,
  wofCancelRequest: null,

  customersListWOF: [],
}

const getters = {}

const actions = {
  [VUEX_API_WOF_CUSTOMER_DETAILS_REQUEST]: async ({ dispatch, commit }, payload) => {
    const { email, region, id } = payload
    if (!email || !region || !id) return

    await commit(VUEX_API_WOF_CUSTOMER_DETAILS_REQUEST)

    if (state.wofCancelRequest) state.wofCancelRequest.cancel()
    await commit(VUEX_API_WOF_SET_CANCEL_TOKEN, 'wofCancelRequest')

    return api.post(`orders/wof/customer/details`, payload, {
      cancelToken: state.wofCancelRequest.token
    }).then(response => {
      commit(VUEX_API_WOF_REQUEST_SUCCESS)
      return {
        ...payload,
        status: ITS__FILE__PROCESSING__STATUS__SUCCESS,
        data: response.data
      }
    }).catch((err) => {
      commit(VUEX_API_WOF_REQUEST_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_WOF_CUSTOMER_AUTOCOMPLETE_REQUEST]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_API_WOF_CUSTOMER_DETAILS_REQUEST)

    if (state.wofCancelRequest) state.wofCancelRequest.cancel()
    await commit(VUEX_API_WOF_SET_CANCEL_TOKEN, 'wofCancelRequest')

    return api.post(`orders/wof/customer/name/autocomplete`, payload, {
      cancelToken: state.wofCancelRequest.token
    }).then(response => {
      commit(VUE_API_WOF_SET_CUSTOMER_LIST, response.data)
      commit(VUEX_API_WOF_REQUEST_SUCCESS)
      return {
        ...payload,
        status: ITS__FILE__PROCESSING__STATUS__SUCCESS,
        data: response.data
      }
    }).catch((err) => {
      commit(VUEX_API_WOF_REQUEST_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `${err.response && err.response.data.message}`
          }
        })
      }
    })
  }
}

const mutations = {
  // Documents
  [VUEX_API_WOF_CUSTOMER_DETAILS_REQUEST]: state => {
    state.wofRequest = ITS__API__REQUEST_TYPE__PENDING
  },
  [VUEX_API_WOF_REQUEST_SUCCESS]: state => {
    state.wofRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },
  [VUEX_API_WOF_REQUEST_FAILED]: state => {
    state.wofRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  [VUE_API_WOF_SET_CUSTOMER_LIST]: (state, data) => {
    state.customersListWOF = data
  },

  // Set Cancelation token
  [VUEX_API_WOF_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
