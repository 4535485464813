import _debounce from 'lodash.debounce'
import { regionToLocation } from '@/helpers/assortmentDataModifiers'

import store from '@/store/store'

import {
  VUEX_CONTROLBAR_SET_DOCUMENT_DATA,

  VUEX_CONTROLBAR_GET_STYLES,
  VUEX_CONTROLBAR_GET_STYLES_SUCCESS,
  VUEX_CONTROLBAR_GET_STYLES_FAILURE,
  VUEX_CONTROLBAR_CLEAR_STYLE_COLORS_DATA,
  VUEX_CONTROLBAR_CLEAR_ALL_STYLE_DATA,
  VUEX_CONTROLBAR_SET_STYLES_LOADING,
  VUEX_CONTROLBAR_SET_COLORS_LOADING,
  VUEX_CONTROLBAR_SET_COLORS_SUCCESS,

  VUEX_CONTROLBAR_GET_WOF_STYLES,
  // VUEX_CONTROLBAR_GET_WOF_STYLE_COLORS,

  VUEX_CONTROLBAR_UPDATE_QUERY,
  VUEX_CONTROLBAR_FETCH_PRODUCTS,

  VUEX_CONTROLBAR_GET_STYLE_COLORS,
  VUEX_CONTROLBAR_GET_STYLE_COLORS_SUCCESS,

  VUEX_CONTROLBAR_SET_TAB
} from '@/store/constants/ui/controlBar'

import {
  VUEX_API_PRODUCTS_REQUEST_FETCH,
  VUEX_API_PRODUCTS_STYLES_FETCH,
  VUEX_API_PRODUCTS_STYLE_COLORS_FETCH,

  VUEX_API_ORDERS_WOF_STYLES_FETCH
  // VUEX_API_ORDERS_WOF_STYLE_COLORS_FETCH
} from '@/store/constants/api'

import {
  VUEX_STATISTICS_SEND
} from '@/store/constants/statistics'

import {
  VUEX_GRID_ROWS_ADD
} from '@/store/constants/ui/grid'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

const state = {
  documentType: {},
  documentOptions: {},

  searchStylesData: [], // Style data from ITS API
  searchStylesLoading: false, // If are currently being looked up
  searchColorsLoading: false, // If are currently being looked up
  styleColorsData: [],

  productsQuery: {},
  currentTab: ''
}

const getters = {}

const actions = {
  [VUEX_CONTROLBAR_SET_DOCUMENT_DATA]: ({ commit }, payload) => {
    commit(VUEX_CONTROLBAR_SET_DOCUMENT_DATA, payload)
  },

  [VUEX_CONTROLBAR_GET_STYLES]: _debounce(async ({ commit, dispatch }, { value, productType, params = {} }) => {
    let requestData = {}
    requestData.data = value
    requestData.params = params
    if (productType) requestData.params['productType'] = productType

    // send from assortment search
    // let assortment = store.state.assortments.assortment
    // if (assortment.productType) requestData.params['productType'] = assortment.productType

    // send from assortment search
    /* if (assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__REGULAR) {
      if (assortment.locationId) requestData.params['locations.code'] = assortment.locationId
      if (assortment.status) requestData.params['locations.lineStatus'] = assortment.status
    } */

    await dispatch(VUEX_API_PRODUCTS_STYLES_FETCH, requestData).then((response) => {
      commit(VUEX_CONTROLBAR_GET_STYLES_SUCCESS, response.data)
    }).catch(() => {
      commit(VUEX_CONTROLBAR_GET_STYLES_FAILURE)
    })
  }, 500),

  [VUEX_CONTROLBAR_GET_STYLE_COLORS]: _debounce(async ({ commit, dispatch }, payload) => {
    let assortment = store.state.assortments.assortment
    let requestData = Object.assign({ data: {}, params: {} }, payload)

    if (assortment.productType) requestData.params['productType'] = assortment.productType

    if (assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__REGULAR) {
      if (assortment.locationId) requestData.params['locations.code'] = assortment.locationId
      if (assortment.status) requestData.params['locations.lineStatus'] = assortment.status
    } else if (assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL &&
               assortment.channel === ITS__ASSORTMENTS__CHANNEL_TYPE__WHOLESALE) {
      Object.assign(requestData, {
        body: {
          markInAssortmentsQuery: {
            state: assortment.state || ITS__ASSORTMENTS__STATE__PUBLISHED,
            orgType: assortment.orgType,
            channel: ITS__ASSORTMENTS__CHANNEL_TYPE__WHOLESALE,
            season: assortment.season,
            year: assortment.year,
            region: assortment.region
          }
        }
      })
    }

    commit(VUEX_CONTROLBAR_SET_COLORS_LOADING)

    await dispatch(VUEX_API_PRODUCTS_STYLE_COLORS_FETCH, requestData).then((response) => {
      commit(VUEX_CONTROLBAR_GET_STYLE_COLORS_SUCCESS, response.data)
      commit(VUEX_CONTROLBAR_SET_COLORS_SUCCESS)
    }).catch(() => {
      commit(VUEX_CONTROLBAR_GET_STYLES_FAILURE)
    })
  }, 500),

  // -------------------------------------------------------------
  // FETCH WOF STYLE/COLORS
  [VUEX_CONTROLBAR_GET_WOF_STYLES]: _debounce(async ({ commit, dispatch }, payload) => {
    await dispatch(VUEX_API_ORDERS_WOF_STYLES_FETCH, payload).then((response) => {
      // Modify WOF colors
      const modifiedData = response.data?.map(item => ({
        style: item.style,
        colors: item.colors?.map(color => ({
          color,
          style: item.style,
          productTyp: 'Footwear',
          disabled: false
        })) || []
      })) || []

      commit(VUEX_CONTROLBAR_GET_STYLES_SUCCESS, modifiedData)
    }).catch(() => {
      commit(VUEX_CONTROLBAR_GET_STYLES_FAILURE)
    })
  }, 500),

  /* [VUEX_CONTROLBAR_GET_WOF_STYLE_COLORS]: _debounce(async ({ commit, dispatch }, payload) => {
    commit(VUEX_CONTROLBAR_SET_COLORS_LOADING)

    await dispatch(VUEX_API_ORDERS_WOF_STYLE_COLORS_FETCH, payload).then((response) => {
      commit(VUEX_CONTROLBAR_GET_STYLE_COLORS_SUCCESS, response.data)
      commit(VUEX_CONTROLBAR_SET_COLORS_SUCCESS)
    }).catch(() => {
      commit(VUEX_CONTROLBAR_GET_STYLES_FAILURE)
    })
  }, 500), */
  // -------------------------------------------------------------

  [VUEX_CONTROLBAR_FETCH_PRODUCTS]: async ({ state, dispatch, commit }, { data, options }) => {
    await commit(VUEX_CONTROLBAR_UPDATE_QUERY, data)

    if (options?.bypassFetch) {
      const obj = {
        type: (options?.emitType) ? options?.emitType : 'products', // i.e., pass in orders here for the orders section, otherwise assume products (which is sued everywhere else)
        rows: data
      }
      dispatch(VUEX_GRID_ROWS_ADD, obj)

      dispatch(VUEX_STATISTICS_SEND, {
        action: ITS__STATISTICS__ACTION_TYPE__ASSORTMENT__ADD_STYLE,
        data: {
          itemCount: data.length
        }
      })
    } else {
      dispatch(VUEX_API_PRODUCTS_REQUEST_FETCH, state.productsQuery).then(response => {
        const obj = {
          type: 'products',
          rows: response.data[0].data
        }
        dispatch(VUEX_GRID_ROWS_ADD, obj)

        let numOfItems = response.data[0].data.length

        dispatch(VUEX_STATISTICS_SEND, {
          action: ITS__STATISTICS__ACTION_TYPE__ASSORTMENT__ADD_STYLE,
          data: {
            itemCount: numOfItems
          }
        })

        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'success',
            message: `${numOfItems} Item${numOfItems > 1 ? 's' : ''} added`
          }
        })
      })
    }
  },

  [VUEX_CONTROLBAR_SET_TAB]: ({ commit }, payload) => {
    commit(VUEX_CONTROLBAR_SET_TAB, payload)
  }
}

const mutations = {
  [VUEX_CONTROLBAR_SET_DOCUMENT_DATA]: (state, data) => {
    state.documentType = data.documentType
    state.documentOptions = data.documentOptions
  },

  [VUEX_CONTROLBAR_UPDATE_QUERY]: (state, data) => {
    let assortment = store.state.assortments.assortment

    delete state.productsQuery['locations.code']
    delete state.productsQuery['locations.lineStatus']
    delete state.productsQuery['region']
    delete state.productsQuery['carryoverDate']
    delete state.productsQuery._options['region']
    delete state.productsQuery._options['carryoverDate']

    let queryData = {
      style: data.id,
      color: data.colors,
      productType: assortment.productType
    }

    if (assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__REGULAR) {
      queryData['locations.code'] = assortment.locationId
      // queryData['locations.lineStatus'] = assortment.status
    }

    let queryOptions = {}
    if (assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL) {
      queryData['locations.code'] = assortment.region ? regionToLocation(assortment.region) : assortment.locationId
      queryData['locations.lineStatus'] = assortment.status
      if (assortment.region) queryOptions['region'] = assortment.region

      if (assortment.channel === ITS__ASSORTMENTS__CHANNEL_TYPE__WHOLESALE && (assortment.region === ITS__REGION__INTERNATIONAL || assortment.region === ITS__REGION__EUROPE)) {
        if (assortment.carryoverDate) queryOptions['carryoverDate'] = assortment.carryoverDate
      }
    }

    Object.assign(state.productsQuery, queryData)
    Object.assign(state.productsQuery._options, queryOptions)
  },

  // Search Style and Colors
  [VUEX_CONTROLBAR_SET_STYLES_LOADING]: state => {
    state.searchStylesLoading = true
  },

  [VUEX_CONTROLBAR_GET_STYLES_SUCCESS]: (state, data) => {
    state.searchStylesData = data
    state.searchStylesLoading = false
  },

  [VUEX_CONTROLBAR_GET_STYLES_FAILURE]: state => {
    state.searchStylesLoading = false
  },

  [VUEX_CONTROLBAR_SET_COLORS_LOADING]: state => {
    state.searchColorsLoading = true
  },

  [VUEX_CONTROLBAR_SET_COLORS_SUCCESS]: state => {
    state.searchColorsLoading = false
  },

  [VUEX_CONTROLBAR_CLEAR_STYLE_COLORS_DATA]: state => {
    state.searchColorsLoading = false
    state.styleColorsData = []
  },

  [VUEX_CONTROLBAR_CLEAR_ALL_STYLE_DATA]: state => {
    state.searchStylesData = []
    state.searchStylesLoading = false
    state.styleColorsData = []
  },

  [VUEX_CONTROLBAR_GET_STYLE_COLORS]: state => {
    state.styleColorsData = []
  },

  [VUEX_CONTROLBAR_GET_STYLE_COLORS_SUCCESS]: (state, data) => {
    state.styleColorsData = data
  },

  [VUEX_CONTROLBAR_SET_TAB]: (state, data) => {
    state.currentTab = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
