import store from '@/store/store'
import router from '@/router'

// import feConstants from '@/store/constants/config/fe_constants'

import {
  VUEX_ROUTING_ENTER_ORDERS_SAMPLE,
  VUEX_ROUTING_ENTER_ORDERS_SAMPLE_DETAILS,
  VUEX_ROUTING_ENTER_ORDERS_PROMO,
  VUEX_ROUTING_ENTER_ORDERS_PROMO_DETAILS,
  VUEX_ROUTING_ENTER_ORDERS_WHOLESALE,
  VUEX_ROUTING_ENTER_ORDERS_WHOLESALE_DETAILS,
  VUEX_ROUTING_ENTER_ORDERS_COORDINATOR_ASSIGNMENTS
} from '@/store/constants/routing/routingOrders'

import {
  VUEX_NOTIFICATIONS_FETCH
} from '@/store/constants/models/notifications'

import {
  VUEX_ORDERS_DETAIL_FETCH,
  VUEX_ORDERS_LIST_FETCH,
  VUEX_ORDERS_SET_SELECTED_COORDINATOR
} from '@/store/constants/models/orders'

import {
  VUEX_ASSIGNMENTS_FETCH_COORDINATORS
} from '@/store/constants/models/assignments'

import {
  VUEX_SUBHEADER_SHOW,
  VUEX_SUBHEADER_HIDE,
  VUEX_SUBHEADER_SET_SIZE
} from '@/store/constants/ui/subheader'

import {
  VUEX_GRID_REKEY,
  VUEX_GRID_ROWDATA_LOADING_START,
  VUEX_GRID_ROWDATA_LOADING_FINISH
} from '@/store/constants/ui/grid'

const state = {}

const getters = {}

const actions = {
  // SAMPLE ORDERS
  [VUEX_ROUTING_ENTER_ORDERS_SAMPLE]: async ({ dispatch }, payload) => {
    const { allowCoordinatorChange } = router.currentRoute.value.meta

    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })

    dispatch(VUEX_NOTIFICATIONS_FETCH, { notificationType: 'SampleOrderNotification' })

    const user = store.state.user.user
    const selectedCoordinator = store.state.orders.selectedCoordinator
    if (!selectedCoordinator /* && isSeniorSampleCoordinator */) {
      const coordinatorAssignments = store.state.properties.data.Orders.SAMPLE.properties.coordinatorAssignmentUnique
      const coordinator = coordinatorAssignments.find(coordinator => coordinator.coordinatorCode === user.userCode)
      dispatch(VUEX_ORDERS_SET_SELECTED_COORDINATOR, { coordinator: coordinator })
    }

    await dispatch(VUEX_GRID_ROWDATA_LOADING_START)
    await dispatch(VUEX_ORDERS_LIST_FETCH, { allowCoordinatorChange }).then(async () => {
      await dispatch(VUEX_GRID_ROWDATA_LOADING_FINISH)
      dispatch(VUEX_GRID_REKEY)
    })
  },

  // SAMPLE DETAILS
  [VUEX_ROUTING_ENTER_ORDERS_SAMPLE_DETAILS]: async ({ dispatch }, payload) => {
    await dispatch(VUEX_ORDERS_DETAIL_FETCH, {
      orderType: 'SampleOrder',
      orderSubtype: ITS__ORDERS__ORDER_TYPE__SAMPLE,
      id: payload.orderId
    })

    dispatch(VUEX_NOTIFICATIONS_FETCH, { notificationType: 'SampleOrderNotification' })

    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__3,
      content: 'Orders/ControlBar/Orders_ControlBar_Details.vue'
    })
  },

  // PROMO ORDERS
  [VUEX_ROUTING_ENTER_ORDERS_PROMO]: async ({ dispatch }, payload) => {
    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__1,
      content: '_core/AppBar/AppBar_Extension_Nav.vue'
    })

    dispatch(VUEX_NOTIFICATIONS_FETCH, { notificationType: 'SampleOrderNotification' })

    await dispatch(VUEX_GRID_ROWDATA_LOADING_START)
    dispatch(VUEX_ORDERS_LIST_FETCH).then(response => {
      dispatch(VUEX_GRID_ROWDATA_LOADING_FINISH)
      dispatch(VUEX_GRID_REKEY)
    })
  },

  // PROMO DETAILS
  [VUEX_ROUTING_ENTER_ORDERS_PROMO_DETAILS]: async ({ dispatch }, payload) => {
    await dispatch(VUEX_ORDERS_DETAIL_FETCH, {
      orderType: 'SampleOrder',
      orderSubtype: ITS__ORDERS__ORDER_TYPE__PROMO,
      id: payload.orderId
    })

    dispatch(VUEX_NOTIFICATIONS_FETCH, { notificationType: 'SampleOrderNotification' })

    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__4,
      content: 'Orders/ControlBar/Orders_ControlBar_Details.vue'
      // closeButton: { visible: true }
    })
  },

  [VUEX_ROUTING_ENTER_ORDERS_WHOLESALE]: async ({ dispatch }, payload) => {
    const { allowCoordinatorChange } = router.currentRoute.value.meta

    dispatch(VUEX_SUBHEADER_HIDE, { content: null })

    /* dispatch(VUEX_NOTIFICATIONS_FETCH, { notificationType: 'SampleOrderNotification' })
    const user = store.state.user.user
    const selectedCoordinator = store.state.orders.selectedCoordinator
    if (!selectedCoordinator) {
      const coordinatorAssignments = store.state.properties.data.Orders.SAMPLE.properties.coordinatorAssignmentUnique
      const coordinator = coordinatorAssignments.find(coordinator => coordinator.coordinatorCode === user.userCode)
      dispatch(VUEX_ORDERS_SET_SELECTED_COORDINATOR, { coordinator: coordinator })
    } */

    await dispatch(VUEX_GRID_ROWDATA_LOADING_START)
    await dispatch(VUEX_ORDERS_LIST_FETCH, { allowCoordinatorChange }).then(async () => {
      await dispatch(VUEX_GRID_ROWDATA_LOADING_FINISH)
      dispatch(VUEX_GRID_REKEY)
    })
  },

  [VUEX_ROUTING_ENTER_ORDERS_WHOLESALE_DETAILS]: async ({ dispatch }, payload) => {
    await dispatch(VUEX_ORDERS_DETAIL_FETCH, {
      orderType: 'WholesaleOrder',
      orderSubtype: ITS__ORDERS__ORDER_TYPE__WHOLESALE,
      id: payload.orderId
    })

    dispatch(VUEX_NOTIFICATIONS_FETCH, { notificationType: 'SampleOrderNotification' })

    dispatch(VUEX_SUBHEADER_SHOW, {
      size: ITS__UI__SUBHEADER__SIZE__5,
      content: 'Orders/ControlBar/Orders_ControlBar_Details.vue'
    })
  },

  [VUEX_ROUTING_ENTER_ORDERS_COORDINATOR_ASSIGNMENTS]: async ({ dispatch }) => {
    dispatch(VUEX_SUBHEADER_SET_SIZE, '0')
    dispatch(VUEX_SUBHEADER_HIDE, { content: null })

    dispatch(VUEX_ASSIGNMENTS_FETCH_COORDINATORS)
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
